<template>
	<div id="wps">

	</div>

</template>

<script>
import WebOfficeSDK from "../../../assets/web-office-sdk-solution-v2.0.6/web-office-sdk-solution-v2.0.6.es";
import { submitUser } from '../../../api/common.js'

export default {
	data() {
		return {
			instance: null,
			app: ''

		}
	},
	mounted() {
		let fileId = this.$route.query.fileId
		let userId = this.$route.query.userId
		userId ? '' : userId = 'ReadOnlyUser'
		// this.openWps('SX20241211XRIMGT', '4d4b771c16f04db28d203fc7bb05524e',userId)
		//SX20240424NAZAZD 旧
		// SX20241211XRIMGT 新
		submitUser(fileId, userId).then(res => {
			const { data } = res
			if (data.code == 200) {
				this.openWps('SX20241211XRIMGT', fileId, userId)
			} else {
				this.$message({
					type: 'error',
					message: '接口错误'
				})
			}
		})


		// this.light()
	},
	methods: {
		async openWps(appId, fileId, userId) {
			this.instance = WebOfficeSDK.init({
				officeType: WebOfficeSDK.OfficeType.Writer,
				appId: appId,
				fileId: fileId,
				mount: '#wps',
			})
			await this.instance.ready()
			const app = this.instance.Application
			const revisions = await app.ActiveDocument.Revisions
			revisions.RevisionsMode = 0
			revisions.ShowRevisionsFrame = true
			app.ActiveDocument.TrackRevisions = true
			if (userId == 'ReadOnlyUser') {
				await app.ActiveDocument.SetReadOnly({
					Value: true
				})
			}

			// setTimeout(() => {
			// 	// 修订框隐藏
			// 	revisions.ShowRevisionsFrame = false
			// }, 2000)
		},
		async light() {
			await this.instance.ready()
			const app = this.instance.Application
			console.log('+++++++++++', this.app);

			// const Execute = await app.ActiveDocument.Find.Execute('123')
			// console.log("Execute", Execute);

			//  Execute.forEach(item=>{
			// 	const font = await app.ActiveDocument.Range(52, 62).Font
			// 	font.Color ='#ffffff'
			// 	app.ActiveDocument.Range(52, 62).Text="您的预约申请已经通过"


			//  })
			// for (let i = 0; i <= Execute.length - 1; i++) {
			// const font = await app.ActiveDocument.Range(Execute[i].pos, Execute[i].pos+Execute[i].len).Font
			// font.Color ='#b03234'
			// app.ActiveDocument.Range(Execute[i].pos, Execute[i].pos+Execute[i].len).Text="baidu"

			// }

			// const font = await app.ActiveDocument.Range(52, 62).Font
			// font.Color ='#ffffff'
			// app.ActiveDocument.Range(52, 62).Text="您的预约申请已经通过"
			// const font = await app.ActiveDocument.Range(0, 20).Font
		},
	}

}
</script>

<style lang="less">
.wps {
	width: 50%;
}
</style>