import request from '@/untils/request'

// https://76zq660754.yicp.fun/api/kfh/queryFormDataUrl?phone=18041989031
// 获取数据接口
export function submitUser(fileId,userId){
	return request({
		url:`/wps_api/v3/3rd/files/saveOptUser/${fileId}/${userId}`,
		method:'get',
		// headers: {'Content-Type': 'multipart/form-data'},
	})
}



