<template>
	<div class="container">
		<div class="layout">
			<WpsIframefrom></WpsIframefrom>
		</div>
	</div>
</template>

<script>
import WpsIframefrom from "./components/wpsIframe.vue";
export default {

	data() {
		return {
			query: {
				serachValue: "",
				auditStatus: ""
			},
			options: [],
			tableData: [{
				yw: '123',
				zyd: '123不合适',
				xgjz: '12',
				xgjy:'456',
				shzt:'通过',
				tjsj:"2010-12-30",
				xgsj:'2024-10-28'

			},]
		}
	},
	components: {
		WpsIframefrom
	}

}
</script>

<style lang="less" scoped>
.container {
	display: flex;
	height: 100%;

	.layout {
		width: 50%;
		height: 100%;
		background: #F2F6FC;
	}

	// 头部筛选样式
	.topBtnBox {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 30px 30px 10px;
		margin-bottom: 20px;

		.leftBox {
			display: flex;
			justify-content: space-between;
			align-items: center;

		}

		.rightBox {
			display: flex;
			justify-content: space-between;
			align-items: center;

		}

		.queryBtn {
			padding: 8px 20px
		}

		::v-deep .queryInput {
			margin-right: 10px;

			.el-input__inner {
				height: 32px;
				line-height: 32px;
				width: 240px;
			}
		}

		::v-deep .querySelect {
			margin-right: 10px;

			.el-input__inner {
				height: 32px;
				line-height: 32px;
				width: 150px;
			}

			.el-input__icon {
				line-height: 32px;
			}
		}
	}

	// 表格样式
	::v-deep .tableBox {
		margin: 0 30px;
		height: calc(~'100%' - 122px);
	}

}
</style>